/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Quantico:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '@angular/material/prebuilt-themes/purple-green.css'; 
@import '~swiper/swiper-bundle.min.css';
@import "app/theme/styles/base";
@import "app/theme/styles/admin";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";
@import "app/theme/styles/rtl";

body, body div, body h1, body h2, body h3, body h4, body span{
    font-family: 'Quantico', sans-serif !important;
}